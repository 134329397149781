<template>
	<svg id="header-logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="276px" height="50.6px" viewBox="82.5 421.2 276 50.6" enable-background="new 82.5 421.2 276 50.6">
		<path d="M148.5,434.2c-2.3,0-3.1,0.9-2.4,3.4l4.3,15.5h0.1l7.3-19.6h1.4l7.1,19.3h0.1c1.6-5.1,3.1-10.2,4.7-15.3c0.7-2.2,0.1-3.3-2.9-3.4v-0.9h8v0.9c-1.5,0.2-2.7,1.3-3,2.8l-6.7,21.6h-1.7l-7.3-19.6l-7.2,19.7h-1.8l-5.5-19.4c-1-3.5-2.2-5-4.1-5v-1h9.7L148.5,434.2L148.5,434.2z"></path>
		<path d="M177.7,457.6c3.3-0.4,3.1-0.2,3.1-3.6v-16.3c0-3.4,0.1-3.2-3.1-3.6v-0.9h16.5v5.8h-0.9c-0.4-2.7-1.1-4.7-3.7-4.7h-4.9v10.4h3c2.3,0,3.3-0.9,3.3-3.1h0.9v7.7h-1c-0.1-2.1-0.9-3.4-3.3-3.4h-3v8.2c0,3.1,1.4,3.3,4.7,3.3c5.3,0,5-1.5,6.7-5.3h0.9l-1.1,6.5h-18.1V457.6z"></path>
		<path d="M213.3,438.6c-0.3-2.5-1.7-4.6-4.6-4.6c-2-0.2-3.8,1.2-4,3.1c0,0.2,0,0.4,0,0.7c0,2.5,2.5,4.1,6.1,6.8c3.8,2.9,5.1,4.7,5.1,7.7c0,4.3-4.4,6.7-8.8,6.7c-2.1,0-4.2-0.4-6.2-1.2c-0.2-0.1-0.3-0.3-0.2-0.5v-5.2h0.9c0,3.1,2.5,5.6,5.6,5.6c0.1,0,0.1,0,0.2,0c2.5,0.1,4.6-1.9,4.7-4.5c0,0,0-0.1,0-0.1c0-2.4-1.3-4.3-6.7-7.8c-2-1.3-4.4-2.7-4.4-6.7c0-3.3,3.6-5.8,7.8-5.8c1.7,0,3.4,0.2,5.1,0.7c0.2,0,0.3,0.2,0.2,0.4v4.7H213.3L213.3,438.6z"></path>
		<path d="M230.4,454c0,3.4-0.2,3.2,3.1,3.6v0.9h-10v-0.9c3.3-0.4,3.1-0.2,3.1-3.6v-19.6h-3.3c-3.1,0-3.9,2-5.1,4.4h-0.9l1-5.5h20.3l1,5.5h-0.9c-1.2-2.4-1.9-4.4-5.1-4.4h-3.3V454L230.4,454z"></path>
		<path d="M349,454c0,3.4-0.2,3.2,3.1,3.6v0.9h-10v-0.9c3.3-0.4,3.1-0.2,3.1-3.6v-19.6H342c-3.1,0-3.9,2-5.1,4.4H336l1-5.5h20.3l1,5.5h-1c-1.2-2.4-1.9-4.4-5.1-4.4H349V454z"></path>
		<path d="M246.8,439L246.8,439l-0.1,14.7c0,0.7,0.1,1.3,0.2,2c0.2,1.3,1.4,1.8,3.1,2v0.9h-8.1v-1c1.7-0.2,2.9-0.7,3.1-2c0.1-0.6,0.2-1.3,0.2-2v-16.5c-0.7-1.8-2.3-3-4.2-3.1v-0.9h7.1l10.1,20.2l10.4-20.2h6.2v0.9c-2.6,0-3.6,1.4-3.6,3.7V454c0,3.4-0.1,3.2,3.1,3.6v0.9h-10v-0.9c3.3-0.4,3.1-0.2,3.1-3.6v-15.2h-0.1l-9.8,19.9h-0.9L246.8,439z"></path>
		<path d="M299.8,446.2c0-8.6-4.7-12.2-9.1-12.2c-6.2,0-8.7,5.5-8.7,10.4c0,8.8,4.5,13.2,9.2,13.2C297.9,457.7,299.8,451.1,299.8,446.2 M277.7,446.4c0-7.5,6-13.5,13.4-13.6c0.1,0,0.3,0,0.4,0c6.9,0,12.5,5.5,12.6,12.4c0.1,7.5-5.9,13.7-13.4,13.9c-0.2,0-0.3,0-0.5,0c-6.9,0-12.5-5.5-12.5-12.4C277.7,446.5,277.7,446.5,277.7,446.4"></path>
		<path d="M329.5,458.5c-5.8-6.8-11.7-13.9-17.6-20.9v16c0,0.7,0.1,1.3,0.2,2c0.2,1.3,1.4,1.8,3.8,2v0.9H307v-1c1.7-0.2,2.9-0.7,3.1-2c0.1-0.6,0.2-1.3,0.2-2v-17c-0.9-1.5-2.5-2.4-4.2-2.5v-0.9h7.2c5.4,6.5,11,13.2,16.4,19.8h0.1v-14.9c0-0.7-0.1-1.3-0.2-2c-0.2-1.3-1.4-1.8-3.8-2v-0.9h8.9v0.9c-1.7,0.2-2.9,0.7-3.1,2c-0.1,0.6-0.2,1.3-0.2,2v20.5H329.5L329.5,458.5z"></path>
		<path d="M117,450l1-0.9h-1.4l-0.6-1.3l-0.7,1.3H114l1,0.9l-0.2,1.4l1.2-0.7l1.2,0.7L117,450z M113.3,459.6c0.1,0.3,0.2,0.5,0.2,0.8c1.7-0.5,3.4-0.5,5.1,0l0.2-0.8C117,459,115.1,459,113.3,459.6 M111.2,453.5l1.7,5l0.1,0.3c1.9-0.5,4-0.5,5.9,0l0.1-0.3l0,0l1.8-5l0,0l-1.6,0.2c0,0,0.1,3.1-1.4,3.1c-0.5,0-0.9-0.4-1-0.9c-0.1-0.5-0.2-0.9-0.2-1.4c0-0.8,0-1.5,0.1-2.3h-1.4c0.1,0.8,0.1,1.5,0,2.3c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.5,0.9-1,1c-1.5,0-1.4-3.1-1.4-3.1L111.2,453.5z M100.9,457h-4.4l0.7,1.1l0.5,0.4l0.6,2.4h0.8l0.5-2.4l0.6-0.4L100.9,457z M97.5,448.8c0.9,1.1-2.7,2.2-0.8,5.1C95.9,451.8,99.3,449.9,97.5,448.8 M99.2,452.8c2.5-4.1-0.6-4.4-0.6-4.4c0.9,0.4,0.6,1.2-0.5,3.1c-0.7,1-0.8,2.3-0.3,3.4h0.6C98.5,454.1,98.7,453.4,99.2,452.8 M100.4,454.3c1.6-2.2,0.4-3.4,0.4-3.4c0.1,0.7,0,1.1-1.1,2.7c-0.3,0.4-0.5,0.8-0.5,1.2h0.8L100.4,454.3 M101.2,455.5h-5.1v0.7h5.1L101.2,455.5L101.2,455.5z M101.4,436.3c-1,0-4.2,0.5-5.4,2.8v-7.2c0-0.6,1.3-2.8,5.4-3V436.3L101.4,436.3z M95.1,439c-1.2-2.2-4.4-2.6-5.4-2.8v-7.4c4.1,0.1,5.4,2.3,5.4,3v7.1V439z M102.6,437.8v-9.2h-0.5v-0.7h-0.4c-3.4,0-5.4,1.4-6.2,2.6c-0.8-1.2-2.7-2.6-6.2-2.6H89v0.7h-0.5v9.1c2.2,0,4.4,0.6,6.2,1.9c0.2,0.2,0.5,0.3,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2c1.8-1.3,4-1.9,6.3-1.9 M122.5,432.8l0.7,0.5v-2.8l-0.7,0.5h-2.9V428l0.5-0.7h-2.7l0.5,0.7v3.1H115l-0.7-0.5v2.7l0.7-0.5h3v6.9l-0.5,0.7h2.7l-0.5-0.7v-6.9L122.5,432.8L122.5,432.8z M130.9,425.9c0.1,6.1-0.6,12.2-1.8,18.2h-21v-21.7C122.4,422.5,129.4,425.2,130.9,425.9 M84,426c1.6-0.8,8.7-3.5,22.9-3.6v21.7H85.8C84.5,438.1,83.9,432,84,426 M86.1,445.3h20.8v25.2C98.9,467.9,90.1,461.3,86.1,445.3 M108.1,470.4v-25.1h20.8C124.9,461.3,116,467.8,108.1,470.4 M132.2,425.6v-0.4l-0.4-0.2c-1.3-0.6-8.4-3.9-24.4-3.9c-16,0-23.1,3.2-24.4,3.9l-0.3,0.2v0.4c0,32.2,13.3,42.9,24.5,46.2h0.2h0.2C118.8,468.5,132.2,457.8,132.2,425.6"></path>
	</svg>
</template>
<script>
export default {
	name : 'WestmontLogo'
}
</script>