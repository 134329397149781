import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import SignIn from '../views/SignIn.vue'
import PasswordForm from '../views/PasswordForm.vue'
import MultiFactorSetup from '../views/MultiFactor/Setup.vue'
import MultiFactorVerify from '../views/MultiFactor/Verify.vue'
import ServerError from '../views/ServerError.vue'
import Profile from '../views/Profile.vue'
import Details from '../views/Details.vue'
import AdminUserList from '../views/Admin/UserList.vue'
import AdminActivityLog from '@/views/Admin/ActivityLog.vue'
import AdminTokens from '@/views/Admin/Token/Index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/admin/2fa',
        name: 'AdminUserList',
        component: AdminUserList
    },
    {
        path: '/admin/activity',
        name: 'AdminActivity',
        component: AdminActivityLog
    },
    {
        path: '/admin/tokens',
        name: 'AdminTokens',
        component: AdminTokens
    },
    {
        path: '/server-error',
        name: 'ServerError',
        component: ServerError
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        component: PasswordForm,
        props: {
            type: 'change'
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: PasswordForm,
        props: {
            type: 'create'
        }
    },
    {
        path: '/2fa/setup',
        name: 'MultiFactorSetup',
        component: MultiFactorSetup
    },
    {
        path: '/2fa/verify',
        name: 'MultiFactorVerify',
        component: MultiFactorVerify
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile
    },
    {
        path: '/details',
        name: 'Details',
        component: Details
    }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
