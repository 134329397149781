import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue                             from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import App                             from './App.vue'
import {abilitiesPlugin}               from '@casl/vue'
import {buildAbilitiesFromPermissions} from "@/services/ability";
import router                          from './router'
import {BootstrapVue, IconsPlugin}     from 'bootstrap-vue'
import '@fortawesome/fontawesome-free/css/all.css'
import './sass/style.scss'

Vue.config.productionTip = false

Vue.use(abilitiesPlugin, buildAbilitiesFromPermissions(window.saml_profile.permissions || []), {useGlobalProperties: true})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.prototype.$alerts = window.saml_messages || [];
Vue.prototype.$api_url = window.api_url || 'api.westmont.edu';
Vue.prototype.$saml_urls = window.saml_urls || {};
Vue.prototype.$profile = window.saml_profile || {
	isLoggedIn    : false,
	isAdmin       : false,
	hasMultiFactor: false,
	username      : "unknown",
	email         : "",
	firstName     : "Unknown",
	lastName      : "",
	groups        : [],
	expires       : null,
	primaryGroup  : "Logged Out",
	fullName      : "Unknown ",
	permissions   : [],
	appLinks      : []
}

let unauthRoutes = ['', '/', '/forgot-password', '/change-password']

router.beforeEach((to, from, next) => {
	let isAuthenticated = router.app.$profile.hasOwnProperty('username');
	let tie = document.getElementById('trackid');
	let nextRoute = {};
	if (tie && tie.innerHTML !== '') {
		if (!to || to.name !== 'ServerError') {
			nextRoute = {name: 'ServerError'};
		}
	}
	else if (!isAuthenticated && unauthRoutes.indexOf(to.fullPath) === -1) {
		nextRoute = {name: 'SignIn'};
	}

	next(nextRoute);
})

new Vue({
	        router,
	        render: h => h(App),
	        created()
	        {
		        // this is here instead of the router.beforeEach due to infinite looping
		        if (window.location.pathname === '/profile') {
			        if (window.location.hash === "#" || window.location.hash === "#/") {
				        this.$router.push('/profile');
			        }
		        }
	        },
			mounted() {
				// nuke the loading-overlay
				document.getElementsByClassName('load-overlay')[0].setAttribute('style', 'visibility: hidden');
			},
	        data: {
		        isLoading       : false,
		        axiosInterceptor: null
	        }
        }).$mount('#app')
