<template>
	<layout-admin>
		<h5 class="card-header d-flex justify-content-between align-items-center">
			Users with MFA enabled
		</h5>
		<div class="card-body">
			<div class="filters">
				<b-input-group>
					<b-form-input v-model="search" type="text" @keyup.enter="applyFilter"/>
					<b-input-group-append>
						<b-button @click="applyFilter">
							<b-icon-search></b-icon-search>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
		</div>
		<b-pagination
			v-model="currentPage"
			align="center"
			:total-rows="pageTotal"
			:per-page="perPage"
			v-on:change="changePage"
		></b-pagination>
		<b-table striped hover sort-icon-left show-empty :items="users" :fields="fields" :busy="isLoading">
			<template v-slot:empty="scope">
				<div class="text-center">No users found</div>
			</template>
			<template #cell(actions)="row" v-if="canResetTokens" class="text-right">
				<b-button class="btn-sm" v-b-modal.confirm @click="modalUsername(row.item.username)">
					<b-icon-trash-fill/>
				</b-button>
			</template>
			<template #cell(created_on)="row">
				<span v-if="row.item.created_on">{{ displayDate(row.item.created_on) }}</span>
			</template>
			<template #table-busy>
				<div class="text-center text-maroon my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
		</b-table>
		<b-modal id="confirm"
		         title="Reset MultiFactor "
		         header-bg-variant="danger"
		         header-text-variant="light"
		         ok-title="Reset"
		         @ok="resetMultiFactor"
		         :busy="isSubmitted"
		         centered
		>
			<b-alert show variant="info">This will clear the existing MFA device from the system.</b-alert>
			<p><strong>Username: </strong> {{ username }}</p>
			<p>If the user is in a group that requires MultiFactor Authentication then they will be prompted on
			   their
			   next login to set it up</p>
			<b-alert :show="resetError" variant="danger">There was a problem resetting the MultiFactor
			                                             Authentication.
			</b-alert>
		</b-modal>
	</layout-admin>
</template>

<script>
import axios       from "axios";
import LayoutAdmin from '@/layouts/admin.vue';
import moment      from "moment";

export default {
	name       : 'AdminUserList',
	components : {
		LayoutAdmin
	},
	created()
	{
		this.applyFilter()
	},
	computed : {
		canResetTokens()
		{
			return this.$can('manage', 'mfa') || this.$can('reset', 'mfa') || this.$can('manage', 'all')
		}
	},
	data     : () => {
		return {
			nextPage    : 1,
			currentPage : 1,
			perPage     : 10,
			pageTotal   : 0,
			isLoading   : true,
			resetError  : false,
			isSubmitted : false,
			username    : '',
			search      : '',
			fields      : [
				{
					key : 'username',
				},
				{
					key   : 'created_on',
					label : 'Enrolled On'
				},
				{
					key         : 'actions',
					label       : '',
					headerTitle : 'Actions',
					tdClass     : 'text-right'
				}
			],
			users       : []
		}
	},
	methods  : {
		displayDate(date)
		{
			return new Date(date).toLocaleDateString("en-US", {
				year   : "numeric",
				month  : "numeric",
				day    : "numeric",
				hour   : 'numeric',
				minute : 'numeric',
				hour12: true,
			})
		},
		changePage(page)
		{
			this.nextPage = page
			this.applyFilter()
		},
		modalUsername(username)
		{
			this.username = username;
		},
		applyFilter()
		{
			this.isLoading = true;
			let params     = {
				page    : this.nextPage,
				perPage : this.perPage
			}
			if (this.search.length > 0) {
				params['q'] = this.search;
			}

			axios.get('/module.php/westmont/api/mfa', {params : params})
			     .then(response => {
				     this.users     = response.data.users;
				     this.pageTotal = response.data.total;
				     this.isLoading = false;
				     this.nextPage  = 1;
			     })
			     .catch(err => {
				     this.isLoading = false
				     this.nextPage  = 1;
			     })
		},
		resetMultiFactor(e)
		{
			e.preventDefault();
			this.isSubmitted = true;
			axios.delete('/module.php/westmont/api/mfa/' + this.username)
			     .then(response => {
				     this.$bvModal.hide('confirm')
				     this.isSubmitted = false;
				     this.resetError  = false;
				     if (this.username === this.$profile.username) {
					     // forcing a reload in case the admin is in a group that requires 2FA.
					     window.location.reload();
				     }
				     else {
					     this.applyFilter();
				     }
			     })
			     .catch(err => {
				     this.resetError = true;
			     })
		}
	}
}
</script>