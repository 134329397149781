<template>
	<div class="col-xs-12 text-center" v-show="this.$profile.isLoggedIn">
		<h4 class="text-nowrap">
			{{ this.$profile.fullName }}<br>
		</h4>
		<div class="badges" v-if="showBadges">
			<b-badge>{{ this.$profile.primaryGroup }}</b-badge>

			<b-badge variant="info" v-if="this.$profile.hasMultiFactor">2FA</b-badge>
		</div>
		<div class="text-center mt-2" v-if="showLogout">
			Not you? <a href="/westmont/logout">Sign Out</a>
		</div>
		<hr class="dual-fade">
	</div>
</template>
<script>
export default {
	name  : "UserHeader",
	props : {
		showLogout : Boolean,
		showBadges : {
			type    : Boolean,
			default : true
		}
	}
}
</script>