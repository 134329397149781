<template>
	<layout-admin>
		<h5 class="card-header d-flex justify-content-between align-items-center">
			Activity
		</h5>
		<b-tabs class="log-tabs">
			<b-tab title="Authentication" @click="showAuthActivity" v-if="canViewAuthLogs"></b-tab>
			<b-tab title="Administration" @click="showAdminActivity" v-if="canViewAdminLogs"></b-tab>
		</b-tabs>
		<div class="card-body">
			<div class="filters">
				<b-input-group>
					<template #append>
						<b-button>
							<b-icon-arrow-repeat v-on:click="applyFilter"></b-icon-arrow-repeat>
						</b-button>
					</template>
					<template #prepend>
						<b-input-group-text>
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
					<b-form-input placeholder="search" v-model="search"
					              v-on:keyup.enter="applyFilter"></b-form-input>
				</b-input-group>
			</div>
		</div>
		<div v-if="displayAuthActivity">
			<b-pagination
				v-model="currentAuthPage"
				align="center"
				:total-rows="authTotal"
				:per-page="perPage"
				v-on:change="changeAuthPage"
			></b-pagination>
			<b-table striped hover sort-icon-left :items="authLogs" :fields="authFields" :busy="isLoading" show-empty>
				<template v-slot:empty="scope">
					<div class="text-center">No logs found</div>
				</template>
				<template #cell(created_at)="row">
					{{ localDate(row.item.created_at) }}
				</template>
				<template #cell(result)="row">
					<div v-if="row.item.event_type === 'authentication'">
						<span v-if="row.item.result === 'SUCCESS'"><i class="text-success fa fa-check"></i> Granted</span>
						<span class="text-danger" v-else><i class="fa fa-x"></i> Denied</span>
					</div>
					<div v-else>
						<span v-if="row.item.result === 'SUCCESS'"><i class="text-success fa fa-lock"></i> Enrolled</span>
						<span class="text-danger" v-else><i class="fa fa-x"></i> Error</span>
					</div>
				</template>
				<template #cell(application_name)="row">
					<span v-if="row.item.event_type === 'authentication'">{{ row.item.application_name }}</span>
					<span v-else>login</span>
				</template>
				<template #cell(factor)="row">
					<span v-if="row.item.event_type === 'authentication'">{{ row.item.factor }}</span>
					<i v-else>unknown</i>
				</template>
				<template #table-busy>
					<div class="text-center text-maroon my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
			</b-table>
		</div>
		<div v-if="displayAdminActivity">
			<b-pagination
				v-model="currentAdminPage"
				align="center"
				:total-rows="adminTotal"
				:per-page="perPage"
				v-on:change="changeAdminPage"
			></b-pagination>
			<b-table striped hover sort-icon-left :items="adminLogs" :fields="adminFields" :busy="isLoading" show-empty>
				<template v-slot:empty="scope">
					<div class="text-center">No logs found</div>
				</template>
				<template #cell(logged_on)="row">
					{{ localDate(row.item.logged_on) }}
				</template>
				<template #cell(target)="row">
					<div v-if="row.item.target_type === 'token'">Token: {{ row.item.target.description }}</div>
					<div v-else>{{ row.item.target }}</div>
				</template>
				<template #table-busy>
					<div class="text-center text-maroon my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
			</b-table>
		</div>
	</layout-admin>
</template>

<script>
import axios       from "axios";
import LayoutAdmin from "@/layouts/admin.vue"

export default {
	name       : 'AdminActivityLog',
	components : {
		LayoutAdmin
	},
	computed   : {
		canViewAuthLogs()
		{
			return this.$can('auth', 'logs') || this.$can('manage', 'all')
		},
		canViewAdminLogs()
		{
			return this.$can('admin', 'logs') || this.$can('manage', 'all')
		}
	},
	data       : () => {
		return {
			isLoading            : true,
			displayAuthActivity  : false,
			displayAdminActivity : false,
			nextPage             : 1,
			currentAuthPage      : 1,
			currentAdminPage     : 1,
			perPage              : 10,
			authLogs             : [],
			authFields           : [
				{
					key   : 'created_at',
					label : 'Date',
				},
				'result',
				{
					key   : 'username',
					Label : 'User'
				},
				{
					key   : 'application_name',
					label : 'Application'
				},
				{
					key   : 'access_device_ip',
					label : 'Access Device'
				},
				{
					key   : 'factor',
					label : 'Authentication Method'
				},
			],
			authTotal            : 0,
			adminLogs            : [],
			adminFields          : [
				{
					key   : 'logged_on',
					label : 'Date'
				},
				'source',
				'action',
				{
					key   : 'target',
					label : 'Affected'
				}
			],
			adminTotal           : 0,
			isSubmitted          : false,
			username             : '',
			search               : '',
			fields               : [
				'username',
				'actions'
			],
			uri                  : '',
			users                : []
		}
	},
	mounted()
	{
		if (this.canViewAuthLogs) {
			this.showAuthActivity();
		}
		else if (this.canViewAdminLogs) {
			this.showAdminActivity();
		}
	},
	methods : {
		changeAuthPage(page)
		{
			this.nextPage = page
			this.applyFilter()
		},
		changeAdminPage(page)
		{
			this.nextPage = page
			this.applyFilter()
		},
		localDate(date)
		{
			return new Date(date).toLocaleDateString("en-US", {
				year   : "numeric",
				month  : "numeric",
				day    : "numeric",
				hour   : 'numeric',
				minute : 'numeric',
				hour12: true,
			})
		},
		modalUsername(username)
		{
			this.username = username;
		},
		showAuthActivity()
		{
			this.isLoading = true;
			let params     = {
				page    : this.nextPage,
				perPage : this.perPage
			}
			if (this.search.length > 0) {
				params['q'] = this.search;
			}

			axios.get(this.uri = '/module.php/westmont/api/activity/auth', {params : params})
			     .then(response => {
				     this.authLogs  = response.data.logs;
				     this.authTotal = response.data.total;
				     this.isLoading = false
				     this.nextPage  = 1;
			     })
			     .catch(err => {
				     this.isLoading = false
				     this.nextPage  = 1;
			     })
			this.displayAdminActivity = false;
			this.displayAuthActivity  = true;
		},
		showAdminActivity()
		{
			this.isLoading = true;
			let params     = {
				page    : this.nextPage,
				perPage : this.perPage
			}
			if (this.search.length > 0) {
				params['q'] = this.search;
			}
			axios.get(this.uri = '/module.php/westmont/api/activity/admin', {params : params})
			     .then(response => {
				     this.adminLogs  = response.data.logs;
				     this.adminTotal = response.data.total;
				     this.isLoading  = false;
				     this.nextPage   = 1;
			     })
			     .catch(err => {
				     this.isLoading = false;
				     this.nextPage  = 1;
			     })
			this.displayAdminActivity = true;
			this.displayAuthActivity  = false;
		},
		applyFilter()
		{
			if (this.displayAdminActivity) {
				this.showAdminActivity();
			}
			else if (this.displayAuthActivity) {
				this.showAuthActivity();
			}
		}
	}
}
</script>
<style lang="scss">
.log-tabs {
	.nav-tabs {
		border-bottom: unset;

		.nav-link {
			border: unset;
			background: unset;

			&.active, &:hover {
				border-bottom: 2px solid var(--westmont-maroon);
			}

			&.active {
				text-decoration: none;

				&:hover {
					border-bottom: 2px solid var(--westmont-teal);
				}
			}
		}
	}
}

#apps.card-deck .card-body {
	padding: .5rem 0;
}
</style>