<template>
	<div class="signin">
		<div class="subheader text-center">
			<hr>
			<h4 class="text-nowrap">Westmont Single Sign-On</h4>
			<hr>
		</div>
		<div class="d-flex justify-content-center">
			<div class="col-12">
				<transition name="fade">
					<div v-show="this.$alerts.length > 0">
						<b-alert v-for="alert in this.$alerts" show :variant="alert.type">
							<span v-if="alert.code === 'RESETPASSWORD'">Your password has expired. <br />Please <router-link to="/change-password" class="action-link action-reset">reset your password</router-link></span>
							<span v-else-if="alert.code === 'AUTHSOURCEERROR'">Unable to contact authentication servers. <div class="error-report-id">Error ID: {{ alert.errorId }}</div></span>
							<span v-else>{{ alert.message }}</span>
						</b-alert>
					</div>
				</transition>
				<div class="card bg-light-gray">
					<form method="post" class="card-body" @submit="doLogin">
						<div class="form-group">
							<input type="text" v-model=username id="username" name="username" class="form-control" tabindex="1" accesskey="u" placeholder="Username" required autofocus  :readonly="submitted">
						</div>
						<div class="form-group">
							<input type="password" v-model=password id="password" name="password" class="form-control" tabindex="2" accesskey="p" placeholder="Password" required  :readonly="submitted">
						</div>
						<div class="form-group">
							<button type="submit" class="btn btn-block btn-westmont" accesskey="l"  value="Log in" tabindex="4" id="login-submit" :disabled="submitted">
								<b-spinner small v-show="submitted"></b-spinner>
								Log in
							</button>
						</div>
						<div class="form-group text-center mb-0">
							<router-link to="/forgot-password"><a class="action-link">Forgot Your Password?</a></router-link><br>
							<router-link to="/change-password"><a class="action-link">Change Password</a></router-link><br>
							<router-link to="/forgot-password"><a class="action-link">Setup New Account</a></router-link>
						</div>
						<input type="hidden" v-model=authState name="AuthState"/>
						<input type="hidden" v-model=relayState name="RelayState"/>
					</form>
				</div>
			</div>
		</div>
		<br>
		<footer class="container d-flex flex-column justify-content-center col-md-8 col-xs-10 col-sm-10">
			<div class="text-center">
				<p class="text">Download the Westmont Mobile App</p>
			</div>
			<div class="d-flex row justify-content-around app-icons">
				<a class="btn btn-icon-text btn-outline-westmont  d-flex" href="https://itunes.apple.com/us/app/westmont/id364494194">
					<i class="fab fa-apple btn-icon-prepend"></i>
					<span>App Store</span>
				</a>
				<a class="btn  btn-icon-text btn-outline-westmont d-flex justify-content-center" href="https://play.google.com/store/apps/details?id=edu.westmont.mywestmontexpo">
					<i class="fab fa-google-play btn-icon-prepend"></i>
					<span>Google Play</span>
				</a>
			</div>
		</footer>
	</div>
</template>
<style scoped>
.action-reset {
    text-decoration: underline;
}
</style>

<script>
	// we do not hijack the login button as we allow simplesaml to do it's job
	export default {
		name: 'SignIn',
		data: () => {
			return {
				error: undefined,
				submitted: false,
				username: '',
				password: '',
				authState: '',
				relayState: '',
			}
		},
		methods: {
			doLogin() {
				this.submitted = true;
				return true;
			},
			displayMessage(alert) {
				let message = alert.message;
				let reportId = '';
				switch (alert.code) {
					case 'RESETPASSWORD':
						message = `Your password has expired. Please <router-link to="/change-password"><a class="action-link">reset your password</a></router-link>`;
						break;
					case 'AUTHSOURCEERROR':
						message = 'There was a problem contacting the authentication servers.';
				}

				return {
					template: `<div>{{message}} {{reportId}}</div>`
				}
				return alert.message;
			}
		},
		mounted() {
			document.getElementById('username').focus()
		},
		created() {
			function propOrElement(attr, obj, id) {
				if (obj.hasOwnProperty('login_state') && obj.login_state.hasOwnProperty(attr)) {
					return obj.login_state[attr];
				}

				let tmp = document.getElementById(id);
				return tmp ? tmp.value : null;
			}

			let ue = propOrElement('ue', this.$profile, 'username');
			let pe = propOrElement('pe', this.$profile, 'password');
			let ase = propOrElement('ase', this.$profile, 'AuthState');
			let rse = propOrElement('ase', this.$profile, 'RelayState');
			this.$profile.login_state = this.$profile.login_state || {};

			ue && (this.username = this.$profile.login_state.ue = ue);
			pe && (this.password = this.$profile.login_state.pe = pe);
			ase && (this.authState = this.$profile.login_state.ase = ase);
			rse && (this.relayState = this.$profile.login_state.rse = rse);
		}
	}
</script>

<style lang="scss">
@import "src/sass/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities/borders";

.signin {
	form {
		.form-group:last-child {
			margin-bottom: 0;
		}
	}

	footer {
		margin-bottom: 1.5rem;

		.app-icons-lg {
			a {
				span {
					font-size: .75rem;
				}
				i {
					font-size: 1.75rem;
					margin-right: 10px;
				}
			}
		}
		.app-icons {

			a {
				line-height: 1rem;
				span {
					font-size: .75rem;
				}
				i {
					font-size: 1rem;
					margin-right: 10px;
				}
			}

		}

		a.icon {
			flex-basis: 55px;
			flex-shrink: 0;
			flex-grow: 0;
			padding: 0;

			img {
				@extend .rounded !optional;
				width: 100%;
				max-width: 55px;
				max-height: 55px;
			}
		}

		p.text {
			color: #888888;
			font-size: 13px;
			font-weight: 900;
			margin-bottom: 5px;
		}
	}

	.icons {
		font-size: 2rem;
		display: flex;
		padding: .75rem;
		margin: auto;

		i {
			margin-left: auto;
			background-color: var(--westmont-maroon);
			background-image: linear-gradient(to bottom, $westmont-maroon, $westmont-dark-maroon);
			background-size: 100%;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.subheader {
		p {
			margin: 0;
			padding: 0;
		}

		hr {
			border: 0;
			height: 1px;
			//background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
			background-image: linear-gradient(to right, rgba(93, 93, 93, 0), rgba(80, 80, 80, 0.25), rgba(93, 93, 93, 0));

			&:first-child {
				margin-bottom: 5px;
			}

			&:not(:first-child) {
				margin-top: 5px;
			}
		}
	}
}
</style>
