<template>
	<div class="LayoutUser">
		<AdminMenu v-if="showMenu"/>
		<user-header :showLogout="showLogout" :showBadges="showBadges"/>
		<slot/>
	</div>
</template>
<script>
import axios      from "axios";
import UserHeader from "@/components/UserHeader"
import AdminMenu  from "@/components/AdminMenu";

export default {
	name : 'LayoutUser',
	components: {
		AdminMenu,
		UserHeader
	},
	props:{
		showLogout: Boolean,
		showBadges: {
			type: Boolean,
			default: true
		},
		showMenu: {
			type: Boolean,
			default: true
		}
	},
	created() {
		// we need to revert any changes from clicking on admin
		document.getElementById('login-portal').setAttribute('class', 'col-12 col-sm-11 col-md-9  col-lg-6 col-xl-5 card');
		let lhc = document.getElementById('login-header').classList;
		lhc.remove('d-none');
		lhc.add('mr-3','ml-3')
	}
}
</script>