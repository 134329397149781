<template>
	<div class="d-flex justify-content-center flex-column">
		<h5 class="text-center">Profile</h5>
		<table class="col-12 table table-condensed table-striped table-constrained ">
			<tbody>
			<tr v-for="(value, propertyName) in this.$profile">
				<th>{{ propertyName }}</th>
				<td>{{ value }}</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default {
		name : 'Details',
	}
</script>
<style lang="scss">
	table th {
		vertical-align: top;
	}
	.table-constrained td {
		white-space: break-spaces;
		word-break: break-all;
		overflow-wrap: break-word;
	}
</style>