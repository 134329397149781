<template>
	<b-navbar type="westmont" fixed="top" v-show="$can('read', 'menu')">
		<b-navbar-brand>
			<WestmontLogo/>
		</b-navbar-brand>
		<b-navbar-nav>
			<b-nav-item to="/admin/2fa" v-if="$can('read', 'mfa')">MFA</b-nav-item>
			<b-nav-item to="/admin/activity" v-if="$can('read', 'logs')">Activity</b-nav-item>
			<b-nav-item to="/admin/tokens" v-if="$can('read', 'tokens')">Tokens</b-nav-item>
		</b-navbar-nav>
		<b-navbar-nav class="ml-auto">
			<b-nav-text v-if="$profile.env !== 'production'">
				<b-badge variant="warning">{{ $profile.env }}</b-badge>
			</b-nav-text>
			<b-nav-item to="/profile">{{ $profile.fullName }}</b-nav-item>
		</b-navbar-nav>
	</b-navbar>
</template>
<script>
import WestmontLogo from "@/components/WestmontLogo";

export default {
	components : {
		WestmontLogo
	}
}
</script>