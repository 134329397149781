<template>
	<div class="container" id="setupForm" :action="action">
		<input type="hidden" name="StateId" v-model="stateId">
		<div class="col-xs-12">
			<h4 class="text-center">Two-Factor Authentication</h4>

			<b-alert :show="isInitialSetup || this.$profile.hasMultiFactor === false">
        Follow these steps to improve the security on your account.
			</b-alert>

			<b-alert :show="this.$profile.hasMultiFactor">
				This will deactivate any previous Two-Factor devices you have set up for Westmont College.
			</b-alert>

			<h5 class="text-center">Approved 2FA authentication apps:</h5>
			<table class="recommended-apps">
				<tr>
					<th>Google Authenticator</th>
					<td class="app-links">
						<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><i class="fab fa-apple" aria-hidden="true" title="Google Authenticator for iOS"></i></a>
						<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank"><i class="fab fa-google-play" aria-hidden="true" title="Google Authenticator for Android"></i></a>
					</td>
				</tr>
			</table>
			<hr class="dual-fade">

			<dl class="row d-flex no-gutters">
				<dt class="col-sm-2">Step 1</dt>
				<dd class="col-sm-10">Open the authenticator mobile app</dd>

				<dt class="col-sm-2">Step 2</dt>
				<dd class="col-sm-10">Scan the QR code below or manually type the secret key into your authenticator app</dd>
				<div class="secret-container col-12">
					<div class="d-flex justify-content-center flex-column">
						<div class="col-12">
							<img :src="secretImage" alt="QR code" v-show="isInitialSetup">
							<QRCanvas :options="qrcode" v-show="!isInitialSetup"></QRCanvas>
						</div>
						<div class="col-8">
							<pre @click="revealCode=true" class="card bg-light secret-code" v-show="!revealCode">Reveal secret key</pre>
							<pre class="card bg-light secret-code" v-show="revealCode">{{ secretCode }}</pre>
						</div>
					</div>
				</div>
				<dt class="col-sm-2">Step 3</dt><dd class="col-sm-10">Enter the generated code from your app</dd>
			</dl>
			<fieldset>
				<transition fade>
					<b-alert :show="message!==''" variant="danger">{{ message }}</b-alert>
				</transition>
				<div class="form-group">
					<input v-model="code" name="code" class="form-control" type="text"
						   required
						   min="1" max="999999" maxlength="6"
						   placeholder="6-digit code from app"
						   @keydown="onlyNumber"
						   :readonly="submitted"
					>
				</div>
				<div class="form-group">
					<button class="btn btn-block btn-westmont" @click="verifyCode" :disabled="submitted || !this.code || (this.code && this.code.length === 0)">
						<b-spinner small v-show="submitted"></b-spinner>
						Continue
					</button>
          <div class="text-center bottom">
            <router-link to="/profile" v-show="this.$profile.isLoggedIn">Cancel</router-link>
          </div>
        </div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import axios      from 'axios'
import {QRCanvas} from 'qrcanvas-vue'

const validNonNumericKeys = [
	'Enter',
	'Backspace'
];

// we do not hijack the login button as we allow simplesaml to do it's job
export default {
	name       : 'MultiFactorSetup',
	components : {
		QRCanvas
	},
	data       : () => {
		return {
			action         : '/module.php/westmont/2fa/verify',
			stateId        : '',
			isInitialSetup : true,
			revealCode     : false,
			qrcode         : {
				data         : '',
				size         : 200,
				correctLevel : 'M'
			},
			message        : '',
			submitted      : false,
			secretCode     : '',
			secretImage    : '',
			displayCode    : false,
			code           : null,
			backupCodes    : []
		}
	},
	created()
	{
		if (this.$profile.hasOwnProperty('initial_setup')) {
			let sc           = document.getElementById('secretCode');
			this.secretCode  = sc.innerText.match(/.{1,16}/g)
			                     .join("\n")
			                     .match(/.{1,4}/g)
			                     .join(' ')
			this.secretImage = document.getElementById('secretImage').src;
			this.stateId     = document.getElementById('StateId').value;
		}
		else {
			this.isInitialSetup = false;
			// this is not their first time setting up 2FA
			axios.get(this.$saml_urls.tfa_setup)
			     .then(response => {
				     this.qrcode     = Object.assign({}, this.qrcode, {data : response.data.uri});
				     this.secretCode = response.data.code.match(/.{1,16}/g)
				                               .join("\n")
				                               .match(/.{1,4}/g)
				                               .join(' ');
			     })
		}
	},
	methods : {
		onlyNumber($event)
		{
			if (validNonNumericKeys.indexOf($event.key) === -1 && !($event.metaKey || $event.ctrlKey)) {
				let keyCode = $event.key.charCodeAt(0);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
					$event.preventDefault();
				}
			}
			else if ($event.key === 'Enter') {
				this.verifyCode();
			}
		},
		verifyCode()
		{
			let self         = this;
			this.submitted   = true;
			let initialSetup = this.$profile.hasOwnProperty('initial_setup');
			let params       = {'code' : this.code};
			if (initialSetup) {
				params['stateId'] = this.stateId;
			}

			axios.post(this.$saml_urls.tfa_setup, params)
			     .then(response => {
				     if (response.headers.hasOwnProperty('x-auth-url')) {
					     window.location.href = response.headers['x-auth-url']
				     }
				     else {
					     this.$profile.hasMultiFactor = true;
					     this.$alerts.push({
						                       type    : 'success',
						                       message : 'Two Factor Authentication has been configured'
					                       });
					     this.$router.push('/profile')
				     }
			     })
			     .catch(err => {
				     self.message   = 'Invalid code'
				     self.submitted = false;
			     })
		}
	}
}
</script>

<style lang="scss">
@import "src/sass/variables";
.recommended-apps {
	margin: auto;
	font-size: 1.25rem;
	a:hover i {
		color: var(--westmont-maroon);
	}
	th {
		text-align: right;
		line-height: 1.5rem;
		vertical-align: middle;
	}
	td {
		&.app-links {
			font-size: 1.5rem;
		}
		i {
			color: $westmont-light-gray;
			padding: 0 .25rem;
			&:first-child {
				padding-left: 1rem;
			}
		}
	}
}
.secret-code {
	white-space: normal;
}
.secret-container {
	text-align: center;
	img {
		max-width: 250px;
		flex-grow: 0;
		flex-shrink: 0;
	}
	.d-flex .col-8 {
		margin: 1rem auto 0;
	}
	pre {
		padding: .5rem;
		font-weight: bold;
	}
}
</style>