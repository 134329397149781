<template>
	<layout-admin>
		<h5 class="card-header d-flex justify-content-between align-items-center">
			Access Tokens
			<b-button size="sm" variant="light" class="ml-1" v-if="canCreateTokens" v-b-modal.create>
				<b-icon-plus></b-icon-plus>
				Create Token
			</b-button>
		</h5>
		<div class="card-body">
			<div class="filters">
				<b-input-group>
					<template #append>
						<b-button>
							<b-icon-arrow-repeat v-on:click="applyFilter"></b-icon-arrow-repeat>
						</b-button>
					</template>
					<template #prepend>
						<b-input-group-text>
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
					<b-form-input placeholder="Find an access token" v-model="search"
					              v-on:keyup.enter="applyFilter"></b-form-input>
				</b-input-group>
			</div>
		</div>
		<b-table striped hover sort-icon-left :items="tokens" :fields="fields" :busy="isLoading" show-empty>
			<template v-slot:empty="scope">
				<div class="text-center">No tokens found</div>
			</template>
			<template #cell(description)="row">
				{{ row.item.description }}
				<div class="ml-2 small text-muted">{{ row.item.token }}</div>
			</template>
			<template #cell(role)="row">
				<b-badge :variant="roleVariant(row.item.role)">{{ row.item.role }}</b-badge>
				<b-icon v-b-tooltip.hover title="System Token" class="ml-2" icon="server"
				        v-if="row.item.type==='system'"></b-icon>
			</template>
			<template #cell(last_used_on)="row">
				<span v-if="row.item.last_used_on"
				      :title="displayDate(row.item.last_used_on)">{{ relativeFrom(row.item.last_used_on) }}</span>
				<i v-else>Never</i>
			</template>
			<template #cell(actions)="row" v-if="canDeleteTokens">
				<b-button size="sm" variant="danger" v-b-modal.confirm v-on:click="actionToken=row.item">
					<b-icon-trash-fill/>
				</b-button>
			</template>
			<template #table-busy>
				<div class="text-center text-maroon my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
		</b-table>

		<b-tooltip target="token-type" title="System Token"></b-tooltip>

		<b-modal id="confirm"
		         title="Delete Token "
		         header-bg-variant="danger"
		         header-text-variant="light"
		         ok-title="Delete"
		         ok-variant="danger"
		         cancel-variant="light"
		         @ok="deleteToken"
		         :busy="isSubmitted"
		         centered
		>
			<p><strong>{{ actionToken.description }} </strong></p>
			<p>Any systems that are currenctly using this token will no longer able to access the REST API</p>
			<b-alert :show="resetError" variant="danger">There was a problem deleting the token.</b-alert>
		</b-modal>
		<b-modal id="create"
		         title="Create an access token "
		         ok-title="Save Token"
		         cancel-variant="light"
		         @ok="createToken"
		         :busy="isSubmitted"
		         centered
		>
			<b-form-group label="Name">
				<b-form-input v-model="token.description" :state="token.validName"></b-form-input>
			</b-form-group>
			<b-form-group label="Role">
				<b-form-select v-model="token.role" :options="roleOptions"></b-form-select>
			</b-form-group>
			<b-form-checkbox v-model="token.type">This is a service token</b-form-checkbox>
		</b-modal>

		<b-modal id="created"
		         title="Access Token"
		         ok-variant="success"
		         @ok="finishToken"
		         :busy="isSubmitted"
		         hide-header-close
		         no-close-on-backdrop
		         no-close-on-esc
		         no-stacking
		         ok-only
		         centered
		>
			<div class="alert alert-success">An API Token has been created successfully</div>
			<p>If you do not copy this token now, you will not be able to retrieve the token again.</p>
			<div class="card card-body bg-light">
				{{ tmpToken }}
			</div>
		</b-modal>
	</layout-admin>
</template>

<script>
import axios       from "axios";
import LayoutAdmin from "@/layouts/admin.vue"
import moment      from 'moment';

export default {
	name       : 'AdminTokens',
	components : {
		LayoutAdmin
	},
	created()
	{
		this.applyFilter()
	},
	computed: {
		canCreateTokens() {
			return this.$can('manage', 'tokens') || this.$can('create', 'tokens') || this.$can('manage', 'all')
		},
		canDeleteTokens() {
			return this.$can('manage', 'tokens') || this.$can('delete', 'tokens') || this.$can('manage', 'all')
		}
	},
	data    : () => {
		return {
			isLoading    : true,
			resetError   : false,
			isSubmitted  : false,
			search       : '',
			actionToken  : {},
			tmpToken     : '',
			fields: [
				{
					key: 'description',
					sortable: true
				},
				{
					key: 'role',
					sortable: true
				},
				{
					key: 'last_used_on',
					sortable: true
				},
				{
					key: 'created_by',
					sortable: true
				},
				{
					key: 'actions',
					label: '',
					headerTitle: 'Actions',
					tdClass: 'text-right'
				}
			],
			roleOptions  : [
				{
					value : 'read-only',
					text  : "Read Only"
				},
				{
					value : 'read-write',
					text  : "Read Write"
				},
			],
			defaultToken : {
				description : '',
				role        : 'read-only',
				type        : 'personal',
			},
			token        : {
				validName   : null,
				description : '',
				role        : 'read-only',
				type        : 'personal',
			},
			tokens       : []
		}
	},
	methods : {
		applyFilter()
		{
			this.isLoading = true;
			axios.get('/module.php/westmont/api/tokens', {params : {q : this.search}})
			     .then(response => {
				     this.tokens    = response.data.tokens;
				     this.isLoading = false
			     })
			     .catch(err => {
				     this.isLoading = false
			     })
		},
		createToken(e)
		{
			e.preventDefault();
			this.isSubmitted = true;
			let data         = Object.assign({}, this.token)
			axios.post('/module.php/westmont/api/tokens', data)
			     .then(response => {
				     this.$bvModal.hide('create');
				     this.isSubmitted = false;
				     this.resetError  = false;
				     this.token       = response.data.token;
				     this.tmpToken    = this.token.token;
				     this.token.token = this.tmpToken.substring(0, 4) + '****' + this.tmpToken.substring(this.tmpToken.length - 4);
				     this.$bvModal.show('created');
			     })
			     .catch(err => {
				     this.isSubmitted = false;
				     this.resetError  = true;
			     })
		},
		deleteToken(e)
		{
			e.preventDefault();
			this.isSubmitted = true;
			axios.delete(`/module.php/westmont/api/tokens/${this.actionToken.id}`)
			     .then(response => {
				     this.$bvModal.hide('confirm')
				     this.isSubmitted = false;
				     this.resetError  = false;
				     let pos          = this.tokens.indexOf(this.actionToken);
				     if (pos !== -1) {
					     this.tokens.splice(pos, 1);
				     }
			     })
			     .catch(err => {
				     this.isSubmitted = false;
				     this.resetError  = true;
			     })
		},
		displayDate(date)
		{
			return moment(date)
				.calendar();
		},
		finishToken(e)
		{
			this.$bvModal.hide('created');
			this.tokens.push(Object.assign({}, this.token));
			this.tmpToken = '';
			this.token    = this.defaultToken;
		},
		relativeFrom(date)
		{
			return moment(date)
				.fromNow();
		},
		roleVariant(role)
		{
			if (role === 'read-write') {
				return 'info';
			}
		}
	}
}
</script>