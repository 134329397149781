<template>
	<div class="LayoutAdmin">
		<AdminMenu/>
		<slot/>
	</div>
</template>
<script>
import axios     from "axios";
import AdminMenu from "@/components/AdminMenu";

export default {
	name : 'LayoutAdmin',
	components : {AdminMenu},
	created() {
		// we need to expand our range for this page
		document.getElementById('login-portal').setAttribute('class', 'd-flex align-items-center col-md-12 card');
		let lhc = document.getElementById('login-header').classList;
		lhc.add('d-none');
		lhc.remove('mr-3', 'ml-3')
	}
}
</script>