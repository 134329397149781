import {AbilityBuilder, Ability, AbilityClass, InferSubjects} from '@casl/ability';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'all' | 'tokens' | 'mfa' | 'logs' | 'menu';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function buildAbilitiesFromPermissions(permissions: any)
{
	const {can, rules} = new AbilityBuilder(AppAbility);
	if (permissions.length > 0) {
		can('read', 'menu')
	}

	// can(permissions, 'all')
	for (let permission of permissions) {
		let perm = permission.split('.', 2);
		can(perm[1], perm[0]);
		can('read', perm[0]);
	}

	return new AppAbility(rules);
}
